import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';

import { ISOLocaleString } from '../../helpers';
import grafanaUrl from '../../lib/util';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  expandroot: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#6d6d6db3',
    position: 'fixed',
    zIndex: '1000',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

const UploadsTable = props => {
  const { className, uploads, ...rest } = props;
  const classes = useStyles();

  const parts = window.location.pathname.split('/');
  const envName = parts[parts.length-1];

  const [expanded, setExpanded] = useState(null);

  const renderLogLink = applog => {
    var log_id = '';
    var aclass = 'expandCell logCell';
    var handler = () => {};

    if (applog !== null) {
      log_id = applog.id;
      handler = () => { window.open('/applogs/' + envName + '?id=' + log_id); };
    } else {
      aclass += ' nologCell';
    }

    return (
      <td className={aclass} title="view applog" data-id={log_id} onClick={handler}>
        <img alt="app log" src='images/logdoc.svg'/>
      </td>
    );
  }

  const renderFile = file => {
    var name = file.filename
      .replaceAll('.cbor', '')
      .replaceAll('.bin', '');

    if (file.hasOwnProperty('missing') && file.missing) {
      fileClass = '';
      procState = 'not uploaded';
      return (
        <td className={`fileCol fileMissing`} title={'not uploaded'}>
        {name}
        </td>
      );
    }

    var fileClass = "fileCol ";
    var subName = "";
    var procState = file.pstate;

    if (!procState) { procState = "ignored"; }

    if (file.import_err) {
      fileClass += "fileError";
    } else if (procState === "imported") {
      fileClass += "fileImported";
    } else if (procState === "sessionized") {
      fileClass += "fileSessionized";
    } else if (procState === "scored") {
      fileClass += "fileScored";
    } else {
      fileClass += "fileIgnored";
    }

    return (
      <td className={`${fileClass} ${file.is_empty ? "fileEmpty" : ""}`} title={procState}>
      {name}
      </td>
    );
  }

  const renderExpandedFile = file => {
    var fileClass = "";
    var procState = file.pstate;
    if (procState === "") {
      procState = "ignored";
    }

    if (file.import_err) {
      fileClass = "fileError";
    } else if (procState === "imported") {
      fileClass = "fileImported";
    } else if (procState === "sessionized") {
      fileClass = "fileSessionized";
    } else if (procState === "scored") {
      fileClass = "fileScored";
    } else {
      fileClass = "fileIgnored";
    }

    return (
      <tr>
        <td title="file id">{file.id}</td>
        <td title="file name">{file.filename}</td>
        <td title="processing state" className={fileClass}>{procState} ({file.pflags})</td>
        <td title="file size">{file.size}B</td>
        <td title="upload timestamp">{ISOLocaleString(file.uploadTimestamp)}</td>
        <td title="team">{file.device.team}</td>
      </tr>
    );
  }

  const renderExpanded = upload => {
    const file_url = '/api/download-bucket/' + envName + '/' + upload.bucket_id
    const point_url = '/api/download-points/' + envName + '/' + upload.bucket_id
    const jira_url = 'https://ipegcorp.atlassian.net/secure/CreateIssueDetails!init.jspa?customfield_11313=' + upload.id + '&customfield_11314=10101&issuetype=10203&pid=11909'

    var gfClass = "download";
    const gfUrl = grafanaUrl(upload.id, upload.start_ts, upload.end_ts);
    if (gfUrl === "") {
      gfClass = "nodownload";
    }

    return (
      <div className={classes.expandroot} onClick={ (ev) => console.log(ev)}>
      <div className="infoBox" onClick={() => {}}>
        <div className="infoContent">
          <div className="infoBoxHeader" onClick={() => setExpanded(null)}>⨯</div>

          <table className="uploadTable">
            {renderUploadOne(upload)}
          </table>

          <b>Bucket UUID</b> {upload.bucket_id}<br/>
          <b>Device Timestamp</b> {ISOLocaleString(upload.device_timestamp)}<br/>
          <b>Duration</b> {upload.duration}<br/>
          <b>Config</b><pre>{JSON.stringify(upload.config,null,2)}</pre>

          <table className="fileTable">
          <thead>
            <th title="file id">file id</th>
            <th title="file name">file name</th>
            <th title="processing state">processing state</th>
            <th title="file size">file size</th>
            <th title="upload timestamp">upload timestamp</th>
            <th title="team">team</th>
          </thead>
          {upload.files.map(renderExpandedFile)}
          </table>
          <div className="issuelink" onClick={() => window.open(jira_url)}>
            <img className="issueicon" alt="open issue" src="/images/issue.png" />
            Report Issue
          </div>
          <div className="downloadRow">
            <div className="download" onClick={() => window.open(file_url)}>
              <img className="dlicon" alt="files" src="/images/document.png" />
              Download Files
            </div>
            <div className="download" onClick={() => window.open(point_url)}>
              <img className="dlicon" alt="points" src="/images/countdown.png" />
              Download Points
            </div>
            <div className={gfClass} onClick={() => (gfUrl !== "") && window.open(gfUrl)}>
              <img className="dlicon" alt="diagnostics" src="/images/circlecompass.png" />
              Diagnostics
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }

  const renderUploadOne = upload => {
    var locatorType = "?";
    var locatorBuildId = "?";
    if (upload.config !== null) {
      locatorType = upload.config.LocatorType
      locatorBuildId = upload.config.BuildId
    }

    if (locatorType.length > 8) {
      locatorType = locatorType.substr(0,8)
    }
    if (locatorBuildId.length > 8) {
      locatorBuildId = locatorBuildId.substr(0,8)
    }

    const ts = ISOLocaleString(upload.files[0].uploadTimestamp);

    return (
      <tr id={upload.id}>
        <td title="upload id">{upload.id}</td>
        <td title="upload timestamp">{ts}</td>
        <td title="upload user">{upload.files[0].device.user}</td>
      </tr>
    );
  }

  const errMsgMap = {
    '⬌ sync': 'SYNC Error occured',
    'overflow': 'BufferOverflowException',
    'connect': "Logger API Connect doesn't respond in specific time",
    '⌀  mps': 'No MPS records available',
    '⌀  dp' : 'Data points are missing',
    '⽍ oom'   : 'Out of memory',
    'net': 'Network request failed',
    '? up': 'getUploadUuid',
  };

  const renderErrLog = alog => {
    var user = '?';
    var errMsg = 'err';

    if (alog.user !== '') {
      user = alog.user;
    }

    for (const k in errMsgMap) {
      if (alog.err_string.includes(errMsgMap[k])) {
        errMsg = k;
        break;
      }
    }

    const parts = window.location.pathname.split('/');
    const envName = parts[parts.length-1];

    const handler = () => { window.open('/applogs/' + envName + '?id=' + alog.id); };

    return (
      <tr id={alog.id} className="errLogRow">
        <td className="errLogCell" title="error log" data-id={alog.id} onClick={handler}>
          <img alt="app log" src='images/logerr.svg'/>
          <div className="errSpacer"/>
          <p>{ISOLocaleString(alog.timestamp)}</p>
          <div className="errSpacer"/>
          <p>{user}</p>
          <div className="errSpacer"/>
          <div className="errLogMsg">{errMsg}</div>
        </td>
      </tr>
    );
  }

  const renderUpload = upload => {
    if (upload.id === -1) {
      return renderErrLog(upload.app_log);
    }

    var user = "?";
    var org = "?";
    var locatorType = "?";
    var locatorBuildId = "?";

    var uparts = upload.files[0].device.user.split("/");
    if (uparts.length >= 3) {
      user = uparts[2];
      org = uparts[0];
    }

    const files = {
      'act.cbor': { filename: 'act.cbor', missing: 1 },
      'aim.cbor': { filename: 'aim.cbor', missing: 1 },
      'bc.cbor':  { filename: 'bc.cbor', missing: 1 },
      'cfg.cbor': { filename: 'cfg.cbor', missing: 1 },
      'dp.cbor':  { filename: 'dp.cbor', missing: 1 },
      'em.cbor':  { filename: 'em.cbor', missing: 1 },
      'mps.cbor': { filename: 'mps.cbor', missing: 1 },
      'raw.bin':  { filename: 'raw.bin', missing: 1 },
    };

    upload.files.forEach(x => {
      if (x.filename.startsWith('rdsync-')) return;
      files[x.filename] = x;
    })

    if (upload.config !== null) {
      locatorType = upload.config.LocatorType
      locatorBuildId = upload.config.BuildId
    }

    // truncate locator type, build id
    if (locatorType.length > 8) {
      locatorType = locatorType.substr(0,8)
    }
    if (locatorBuildId.length > 8) {
      locatorBuildId = locatorBuildId.substr(0,8)
    }

    return (
      <tr id={upload.id}>
        <td className="expandCell" title="more info" data-id={upload.id} onClick={() => setExpanded(upload.bucket_id)}>
          <div className="expandIcon">+</div>
        </td>
        {renderLogLink(upload.app_log)}
        <td title="upload id">{upload.id}</td>
        <td title="upload timestamp" className="timestamp">
          {ISOLocaleString(upload.files[upload.files.length-1].uploadTimestamp)}
        </td>
        <td title="upload user">{user}</td>
        <td title="locator type">{locatorType}</td>
        {Object.values(files).map(renderFile)}
      </tr>
    );
  }

  var expandedUp = false;
  if (expanded !== null) {
    for (var i = 0; i < uploads.length; i++) {
      if (uploads[i].bucket_id === expanded) {
        expandedUp = uploads[i];
      }
    }
  }

  return (
    <div className={classes.root}>
      <table className="uploadTable">
        {uploads.map(renderUpload)}
      </table>
      {expanded !== null ? renderExpanded(expandedUp) : '' }
    </div>
  );
}

export default UploadsTable
